body {
  margin: 0;
  font-weight: normal;
  color: #333;
  /* font-family: "Tinos", serif; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  outline-style: none;
}

.errorBox {
  text-align: center;
  position: absolute;
  z-index: 1;
  color: #d51f3a;
  font-size: 1.2em;
  margin: 10px;
  padding: 0.7em;
  background-color: white;
  border: 1px solid #d51f3a;
  border-radius: 8px;
  bottom: 10%;
  opacity: 0%;
  height: max-content;
}

.errorBox.visible {
  z-index: 4;
  opacity: 90%;
  transform: translateY(150%);
  transition: 0.5s linear;
  width: 90%;
}

.successBox {
  text-align: center;
  position: absolute;
  z-index: 1;
  color: green;
  font-size: 1.2em;
  margin: 10px;
  padding: 0.7em;
  background-color: white;
  border: 1px solid green;
  bottom: 10%;
  opacity: 0%;
  height: max-content;
}

.selected {
  background-color: #4bb8e2 !important;
}

.blueBtn {
  text-align: center;
  height: 1.8em;
  padding: 10px;
  font-size: 13px;
  background-color: #4bb8e2;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  box-shadow: 3px 3px 0px #444;
  padding-bottom: 28px;
}

.blueBtn:hover {
  cursor: pointer;
}

.blueBtn:disabled {
  background-color: lightgray;
}

.selected {
  transform: scale(1.2);
  opacity: 100%;
  background-color: yellow !important;
}

.totalOrder {
  font-weight: bold;
  color: #2e7d32;
  font-size: 14px;
  width: 50%;
}

.innerContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.gridContainer {
  display: flex;
  position: relative;
  height: 100%;
  width: 90%;
  min-width: fit-content;
}

.purchaseToolbar {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
  top: 100px;
  left: 200px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #2e7d32;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 20px;
}

@media all and (max-width: 1000px) {
  .gridContainer {
    left: 50px; 
  }

  .blueBtn {
    font-size: 10px;
  }
}

@media all and (max-width: 784px) {
  .gridContainer {
    left: 200px;
  }
}

@media all and (max-width: 578px) {
  .errorBox.visible {
    font-size: 12px;
    padding: 1px;
  }

  .gridContainer {
    left: 130px;
  }

  .gridContainer * {

    font-size: 10px;
  }

}

@media all and (max-width: 474px) {
  .gridContainer {
    left: 180px;
  }
}

@media all and (max-width: 372px) {
  .gridContainer {
    left: 200px;
  }
}

@media all and (max-width: 336px) {
  .gridContainer {
    left: 250px;
  }
}

@media all and (max-width: 280px) {
  .gridContainer {
    left: 270px;
  }
}

@media all and (max-width: 188px) {
  .gridContainer {
    left: 300px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-body {

  background-color: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-body {
  margin-top: 50px;
}

.rightAlign {
  text-align: right;
}

.App-link {
  color: #61dafb;
}


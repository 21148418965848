.grid-container {

    width: 800px;
    height: 100vh
}

.MuiDataGrid-toolbarContainer {
    position: sticky;
    top:0;
    z-index:1;
    background-color: white;
    opacity: 100%;
}

.MuiDataGrid-columnHeaders {

    position: sticky;
    top:50;
    z-index:1;
    background-color: white;
    opacity: 100%;

}

@media all and (max-size: 1154px) {

    .grid-container {

        font-size: 10px;

    }

    .MuiDataGrid-toolbarContainer {
        font-size: 10px

    }

}
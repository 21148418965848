.partnerForm {

    font-size: 13px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    border-radius: 8px;
    padding: 30px;
    width: 50vw;
    background-color: #ffffff
}



.formWrapper {

    background-color: #ddeff7;
    padding: 50px;
    border-radius: 10px;
}

.row {

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 95%;

}

.container label {

    position: absolute;
    letter-spacing: 0.2px;
    text-align: left;
    font-size: 13px;
    top: 20%;
    left: 3%;
    z-index: 1;
    font-weight: bold;
    width: 100%;
    height: 100%
}

.focused {

    font-weight: bold;
    color: #2c9de9;
    transition: cubic-bezier(.16, 1.04, 1, .95) 0.5s;
    transform: translateY(-70%)
}

.focusedInput {

    border-color:  #4bb8e2;
}


input {

    text-align: left;
    box-sizing: border-box;
    width: 310px;
    height: 50px;
    z-index: 2;
    opacity: 50%;
    font-family: 'Open Sans', sans-serif;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 15px;
    border-radius: 8px;
    border: 2px solid;
    border-color: rgba(128, 128, 128, 0)
}



.partnerForm .row .container {

    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%
}

.partnerForm .row .container input {

    background-color: #efefef;
    text-align: left;
    width: 105%;
    padding: 0.6em
}


.partnerForm .row .container select {

    background-color: #efefef;
    text-align: left;
    width: 102%;
    padding: 0.7em
}

.partnerForm .row .container textarea {

    background-color: #efefef;
    text-align: left;
    width: 102%;
    padding: 0.6em
}




@media all and (max-width: 1294px) {

    input {
        letter-spacing: 2px;

    }

}

@media all and (max-width: 964px) {
    /* 
        input {

            letter-spacing: 0px;
            font-size: 12px;
        } */

    .partnerForm {

        width: 80vw
    }

}

@media all and (max-width: 578px) {

    .partnerForm {

        width: 100vw;
    }

    .partnerForm input {

        letter-spacing: 0px;
        font-size: 12px;
    }

    .partnerForm label {

        font-size: 10px;
    }
}

@media all and (max-width: 366px) {

    .partnerForm input {

        font-size: 10px;
    }
}